"use client";

import { Dropdown } from "flowbite-react";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useRouter } from "next/navigation";
import React from "react";
import { useTranslation } from "react-i18next";
import i18nConfig, { Locale } from "../../../../../../i18nConfig";
import { HiCheck } from "react-icons/hi2";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const router = useRouter();
  const currentPathname = usePathname();

  const handleChange = (e: Locale) => {
    const newLocale = e;

    // set cookie for next-i18n-router
    const days = 30;
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = date.toUTCString();
    document.cookie = `NEXT_LOCALE=${newLocale};expires=${expires};path=/`;

    // redirect to the new locale path
    if (
      currentLocale === i18nConfig.defaultLocale &&
      !i18nConfig.prefixDefault
    ) {
      router.push("/" + newLocale + currentPathname);
    } else {
      router.push(
        currentPathname.replace(`/${currentLocale}`, `/${newLocale}`)
      );
    }

    router.refresh();
  };

  const lngMapper: { [key: Locale]: string } = {
    en: "English",
    fr: "French",   
  };

  return (
    <footer className="w-full bg-[#282C2C]">
      <div className="mx-auto w-full max-w-6xl px-4 py-10">
        <div className="mb-[28px] flex w-full flex-col items-start md:mb-[48px]">
          <div className="mb-[18px] flex w-full flex-col items-center justify-between gap-[18px] md:mb-2 md:flex-row">
            <div className="items- flex gap-1.5">
              <Image
                alt="Logo Swiss Cover"
                width="135"
                height="28"
                src="/images/swiss-cover_white.png"
              />
            </div>
            <div className="flex items-center gap-6">
              <Link
                className="group flex flex-1 flex-col items-center justify-center gap-3 md:flex-row"
                href="tel:+410225187142"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                    stroke="#2F8AC2"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  ></path>
                </svg>
                <span className="text-sm leading-8 text-white group-hover:underline md:text-base">
                  (+41) 022 123 45 67
                </span>
              </Link>
              <Link
                className="group flex flex-1 flex-col items-center justify-center gap-3 md:flex-row"
                href="mailto:contact@swiss-cover.ch"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V11.5"
                    stroke="#2F8AC2"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
                    stroke="#2F8AC2"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M19.21 14.7702L15.6701 18.3102C15.5301 18.4502 15.4 18.7102 15.37 18.9002L15.18 20.2502C15.11 20.7402 15.45 21.0803 15.94 21.0103L17.29 20.8202C17.48 20.7902 17.75 20.6602 17.88 20.5202L21.4201 16.9803C22.0301 16.3703 22.3201 15.6603 21.4201 14.7603C20.5301 13.8703 19.82 14.1602 19.21 14.7702Z"
                    stroke="#2F8AC2"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M18.7002 15.2803C19.0002 16.3603 19.8402 17.2003 20.9202 17.5003"
                    stroke="#2F8AC2"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <span className="text-sm leading-8 text-white group-hover:underline md:text-base">
                  contact@swiss-cover.ch
                </span>
              </Link>
            </div>
          </div>
          {/* <div className="flex flex-col gap-[18px] md:flex-row">
            <a
              className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
              href="/en/about-us"
            >
              About Swiss Cover
            </a>
            <a
              className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
              href="/en/legal-notice"
            >
              Legal notice
            </a>
            <a
              className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
              href="/en/privacy-policy"
            >
              Privacy policy
            </a>
            <a
              className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
              href="/en/faq"
            >
              FAQ
            </a>
            <a
              className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
              href="/en/plan-du-site"
            >
              Sitemap
            </a>
          </div> */}
        </div>

        <div className="mb-[28px] grid grid-cols-1 gap-7 md:mb-[48px] md:grid-cols-2 xl:grid-cols-4">
          <div className="flex flex-col">
            <span className="mb-[22px] text-[18px] font-semibold text-white">
              {t("Footer.key1")}
            </span>
            {/* <div className="flex flex-col gap-[18px]">
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/car-insurance"
              >
                All about car insurance
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/car-insurance/electric-car"
              >
                Electric car insurance
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/car-insurance/mileage-insurance"
              >
                Kilometer-based car insurance
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/car-insurance/low-priced"
              >
                Low-cost car insurance
              </a>
            </div> */}
          </div>
          <div className="flex flex-col">
            <span className="mb-[22px] text-[18px] font-semibold text-white">
              {t("Footer.key2")}
            </span>
            {/* <div className="flex flex-col gap-[18px]">
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/health-insurance"
              >
                2024 health insurance comparison
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/health-insurance/lamal"
              >
                Basic health insurance (LAMal)
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/health-insurance/complementary"
              >
                Complementary health insurance
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/health-insurance/lamal/lamal-or-cmu"
              >
                Cross-border commuters: LAMal or CMU?
              </a>
            </div> */}
          </div>
          <div className="flex flex-col">
            <span className="mb-[22px] text-[18px] font-semibold text-white">
              {t("Footer.key3")}
            </span>
            {/* <div className="flex flex-col gap-[18px]">
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/third-pilar/comparison"
              >
                3rd pillar comparison
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/third-pilar"
              >
                Everything you need to know about the 3rd pillar
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/third-pilar/3a-or-3b"
              >
                Choosing between 3rd pillar A and B
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/third-pilar/maximum-amount"
              >
                Maximum deductible amount
              </a>
            </div> */}
          </div>
          <div className="flex flex-col">
            <span className="mb-[22px] text-[18px] font-semibold text-white">
              {t("Footer.key4")}
            </span>
            {/* <div className="flex flex-col gap-[18px]">
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/mortgage"
              >
                All about mortgage
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/mortgage/calculation"
              >
                Mortgage calculator
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/mortgage/comparison"
              >
                Mortgage comparator
              </a>
              <a
                className="text-sm font-normal leading-5 text-white opacity-60 hover:underline hover:opacity-80"
                href="/en/mortgage/deferred-effects"
              >
                Deferred mortgage
              </a>
            </div> */}
          </div>
        </div>

        <div className="border-t border-white/20 py-5">
          <div className="flex flex-col-reverse justify-between gap-3 md:flex-row md:gap-20">
            <div className="flex flex-col gap-2">
              <span className="text-xs font-medium leading-4 text-white opacity-60">
                {t("Footer.key6")}
              </span>
            </div>
            <div>
              <Dropdown
                label=""
                dismissOnClick={true}
                renderTrigger={() => (
                  <div className="w-full md:w-40">
                    <button
                      type="button"
                      role="combobox"
                      aria-controls="radix-:R1dv6:"
                      aria-expanded="false"
                      aria-autocomplete="none"
                      dir="ltr"
                      data-state="closed"
                      className="flex h-[56px] w-full items-center justify-between rounded-lg border px-4 py-[18px] text-sm ring-offset-white focus:outline-none focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[placeholder]:text-grey [&amp;>span]:line-clamp-1 bg-[#282C2C] text-white border-neutral-600 focus:outline-0 focus:outline-neutral-600 focus:ring-neutral-600 focus:ring-0 focus:border-neutral-600 focus:ring-offset-transparent"
                    >
                      <span>{lngMapper[currentLocale]}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-4 w-4 opacity-50"
                        aria-hidden="true"
                      >
                        <path d="M6 9l6 6l6 -6"></path>
                      </svg>
                    </button>
                  </div>
                )}
                className="bg-[#282C2C] py-0 border-neutral-600"
              >
                <Dropdown.Item
                  className="text-white hover:!bg-gray-500"
                  onClick={() => handleChange("en")}
                >
                  <span className="min-w-6">
                    {currentLocale === "en" && <HiCheck size={16} />}
                  </span>
                  English
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-white hover:!bg-gray-500"
                  onClick={() => handleChange("fr")}
                >
                  <span className="min-w-6">
                    {currentLocale === "fr" && <HiCheck size={16} />}
                  </span>
                  French
                </Dropdown.Item>               
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
