"use client";

import { Alert, Label, Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import PhoneIcon from "../../../app/assets/icons/PhoneIcon";
import CustomSelect from "../../../components/CustomSelect";
import { usePostAssuranceRequestMutation } from "@/lib/services/v1";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";
import { toggleCallRequestModal } from "./slice";
import { usePathname } from "next/navigation";

type PayloadType = {
  phone: string;
  category: string;
};

const AdvisorCallForm = () => {
  const { t } = useTranslation();
  const pathName = usePathname();
  const dispatch = useAppDispatch();

  const { isCallRequestModalVisible } = useAppSelector((state) => state.ui);

  const [onFinish, onFinishResults] = usePostAssuranceRequestMutation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm<PayloadType>();

  const onSubmit = (data: PayloadType) => {
    onFinish({
      service: data.category,
      data: {
        phone: data.phone,
      },
    })
      .unwrap()
      .then(() => {
        reset();

        toast.success("Thanks. We will contact you as soon as possible.");

        dispatch(toggleCallRequestModal(false));
      })
      .catch((err) => {
        console.error(err);

        toast.error(err.data.message || err.message);
      });
  };

  return (
    <Modal
      dismissible
      show={isCallRequestModalVisible}
      onClose={() => dispatch(toggleCallRequestModal(false))}
      size={"xl"}
    >
      <Modal.Header className="border-0 pb-0 text-[20px] font-semibold items-center">
        {t("Header.key2")}
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="mb-4">
              <Label
                className="text-base leading-5"
                value={t("AdvisorCallForm.key1")}
              />
              <div className="my-[14px] h-[56px] rounded-lg border-[1.5px] border-[#88889459] bg-white px-[14px] focus-within:border-2 focus-within:border-primary [&>input[type=tel]]:focus-within:outline-none PhoneInput">
                <PhoneInputWithCountry
                  name="phone"
                  control={control}
                  rules={{ required: true, validate: isPossiblePhoneNumber }}
                  defaultCountry="CH"
                  autoComplete="false"
                  placeholder={t("AdvisorCallForm.key2")}
                />
              </div>
            </div>

            {!pathName.includes("services") && (
              <div className="mb-4">
                <Label
                  className="text-base leading-5"
                  value={t("AdvisorCallForm.key3")}
                />
                <CustomSelect
                  name="category"
                  control={control}
                  rules={{ required: true }}
                  options={[
                    {
                      label: t("AdvisorCallForm.key4"),
                      value: "health-insurance",
                    },
                    {
                      label: t("AdvisorCallForm.key5"),
                      value: "car-insurance",
                    },
                    {
                      label: t("AdvisorCallForm.key6"),
                      value: "legal-protection",
                    },
                    {
                      label: t("AdvisorCallForm.key7"),
                      value: "home-insurance",
                    },
                    { label: t("AdvisorCallForm.key8"), value: "mortgage" },
                    { label: t("AdvisorCallForm.key9"), value: "pension" },
                  ]}
                />
              </div>
            )}

            <Alert
              color=""
              className="mb-1 relative w-full rounded-xl py-[14px] px-[24px] [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 bg-[#EBF5FF] !text-[#2D50E6] [&>svg]:text-[#2D50E6]"
            >
              {t("AdvisorCallForm.key10")}
            </Alert>

            <button
              type="submit"
              className="w-full inline-flex items-center justify-center whitespace-nowrap rounded-[56px] text-base font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-center bg-thirdy text-white hover:bg-thirdy/90 h-[56px] px-[26px] mt-[14px]"
              disabled={!isValid || onFinishResults.isLoading}
            >
              <PhoneIcon />
              {!pathName.includes("services")
                ? t("AdvisorCallForm.key11")
                : t("AdvisorCallForm.key12")}
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AdvisorCallForm;
