import { ISharedServiceState } from "@/lib/types";
import helpers from "@/utils/helpers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
export interface Research {
  radius: number;
  budget: number[];
  npa?: string;
}

export interface FondsPropres {
  fonds_propres: number;
  lpp?: number;
  pilier3?: number;
  donation?: number;
  autre?: number;
}

export interface Charge {
  type: string;
  montant?: number;
}

export interface RevenueOther {
  type: string;
  montant?: number;
}

export interface Renouvellement {
  month: string;
  year: string;
}

// Type for the "project" field
type ProjectType = "nouveau" | "capacité d'emprunt" | "renouveller hypothèque";

// Type for the "which_step" field
type WhichStepType =
  | "recherche bien"
  | "bien trouvé"
  | "offre acceptée"
  | "bientot signer"
  | "signer";

// Type for the "residence_type" field
type ResidenceType = "principal" | "secondaire" | "invest" | "other";

// Type for the "bien_type" field
type BienType = "maison" | "appartement" | "building" | "construction";

export interface IHypotheque extends ISharedServiceState {
  data: {
    nom: string;
    prenom: string;
    phone: string;
    email: string;
    dob: string;
    research: Research;
    revenue: number;
    bien_price: number;
    verified: boolean;
    fonds_propres: FondsPropres;
    charge: Charge[];
    revenue_other: RevenueOther[];
    renouvellement?: Renouvellement;
    canton_bien?: string;
    financement_actuel?: number;
    emprunteur?: string;
    ddn?: string;
    ddn_2eme_emprunteur?: string;
    project?: ProjectType;
    which_step?: WhichStepType;
    residence_type?: ResidenceType;
    bien_type?: BienType;
    do_work?: boolean;
  };
}

type PayloadPossibleTypes =
  | string
  | boolean
  | number
  | Research
  | FondsPropres
  | Charge[]
  | RevenueOther[]
  | Renouvellement
  | number[]
  | ProjectType
  | WhichStepType
  | ResidenceType
  | BienType;

const initialState: IHypotheque = {
  versionId: "",
  _start: "",
  data: {
    nom: "",
    prenom: "",
    phone: "",
    email: "",
    dob: "",
    research: {
      radius: 30,
      budget: [700000, 1300000],
    },
    revenue: 130000,
    bien_price: 700000,
    verified: false,
    fonds_propres: {
      fonds_propres: 80000,
    },
    charge: [],
    revenue_other: [],
  },
  stepWithError: [],
  currentStep: {
    id: "project",
    group: "project",
    branch: "standard",
  },
  currentVisibleStep: {
    id: "project",
    group: "project",
    branch: "standard",
  },
  loaded: true,
  errors: {},
};

const hypothequeSlice = createSlice({
  name: "hypotheque",
  initialState,
  reducers: {
    reset: () => initialState,

    initWizard: (state) => {
      state.versionId = helpers.generateUUIDv4();
      state._start = new Date().toISOString();
      state.loaded = true;
    },

    updateData: (
      state,
      action: PayloadAction<{
        data: {
          item: keyof IHypotheque["data"];
          value: PayloadPossibleTypes;
        };
        currentStep?: {
          id: string;
          group?: string;
          newTab?: boolean;
          branch?: string;
        };
        currentVisibleStep?: {
          id: string;
          group?: string;
          newTab?: boolean;
          branch?: string;
        };
      }>
    ) => {
      const { item, value } = action.payload.data;

      const updatedData = {
        ...state.data,
        [item]: value as IHypotheque["data"][typeof item],
      };

      state.data = updatedData;

      if (
        action.payload.currentStep &&
        state.currentStep.id !== "info" &&
        state.currentStep.group !== "my_contact"
      ) {
        state.currentStep.id = action.payload.currentStep.id;

        if (action.payload.currentStep.group)
          state.currentStep.group = action.payload.currentStep.group;

        if (action.payload.currentStep.branch)
          state.currentStep.branch = action.payload.currentStep.branch;

        if (action.payload.currentStep.newTab)
          state.currentStep.newTab = action.payload.currentStep.newTab;
        else delete state.currentStep.newTab;
      }

      if (action.payload.currentVisibleStep) {
        state.currentVisibleStep.id = action.payload.currentVisibleStep.id;

        if (action.payload.currentVisibleStep.group)
          state.currentVisibleStep.group =
            action.payload.currentVisibleStep.group;

        if (action.payload.currentVisibleStep.branch)
          state.currentVisibleStep.branch =
            action.payload.currentVisibleStep.branch;

        if (action.payload.currentVisibleStep.newTab)
          state.currentVisibleStep.newTab =
            action.payload.currentVisibleStep.newTab;
        else delete state.currentVisibleStep.newTab;
      }
    },

    updateCurrentStep: (
      state,
      action: PayloadAction<{
        id: string;
        group?: string;
        branch?: string;
        newTab?: boolean;
      }>
    ) => {
      if (state.stepWithError.length > 0)
        toast.error("Please correct the errors before proceeding.");
      else
        state.currentStep = {
          ...state.currentStep,
          ...action.payload,
        };
    },

    updateCurrentVisibleStep: (
      state,
      action: PayloadAction<{
        id: string;
        group?: string;
        branch?: string;
        newTab?: boolean;
      }>
    ) => {
      if (state.stepWithError.length > 0)
        toast.error("Please correct the errors before proceeding.");
      else
        state.currentVisibleStep = {
          ...state.currentVisibleStep,
          ...action.payload,
        };
    },

    addStepError: (state, action: PayloadAction<string>) => {
      if (state.stepWithError.indexOf(action.payload) === -1)
        state.stepWithError.push(action.payload);
    },

    removeStepError: (state, action: PayloadAction<string>) => {
      state.stepWithError = state.stepWithError.filter(
        (item) => item !== action.payload
      );
    },

    checkIfErrorIsResolved: (state, action: PayloadAction<string>) => {
      if (
        Object.hasOwn(state.data, action.payload) &&
        state.data[action.payload as keyof IHypotheque["data"]] !== "" &&
        state.stepWithError.indexOf(action.payload) !== -1
      ) {
        state.stepWithError = state.stepWithError.filter(
          (item) => item !== action.payload
        );
      }
    },
  },
});

export const {
  reset,
  initWizard,
  updateData,
  updateCurrentStep,
  updateCurrentVisibleStep,
  addStepError,
  removeStepError,
  checkIfErrorIsResolved,
} = hypothequeSlice.actions;

export default hypothequeSlice.reducer;
