import { useAppSelector } from "@/lib/hooks";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { useSelector } from "react-redux";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    isCallRequestModalVisible: false,
  },
  reducers: {
    toggleCallRequestModal(state, action: PayloadAction<boolean>) {
      state.isCallRequestModalVisible = !state.isCallRequestModalVisible;
    },
  },
});

export const { toggleCallRequestModal } = uiSlice.actions;

export default uiSlice.reducer;
