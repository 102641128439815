"use client";

import { useRouter } from "next/navigation";
import { usePathname } from "next/navigation";
import { useTranslation } from "react-i18next";
import i18nConfig, { Locale } from "../../i18nConfig";
import { Dropdown } from "flowbite-react";
import ChevronDownIcon from "../app/assets/icons/ChevronDownIcon";

export default function LanguageChanger() {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const router = useRouter();
  const currentPathname = usePathname();

  const handleChange = (e: Locale) => {
    const newLocale = e;

    // set cookie for next-i18n-router
    const days = 30;
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = date.toUTCString();
    document.cookie = `NEXT_LOCALE=${newLocale};expires=${expires};path=/`;

    // redirect to the new locale path
    if (
      currentLocale === i18nConfig.defaultLocale &&
      !i18nConfig.prefixDefault
    ) {
      router.push("/" + newLocale + currentPathname);
    } else {
      router.push(
        currentPathname.replace(`/${currentLocale}`, `/${newLocale}`)
      );
    }

    router.refresh();
  };

  return (
    <Dropdown
      dismissOnClick={true}
      label=""
      renderTrigger={() => (
        <div className="w-fit">
          <button className="flex w-full items-center justify-between rounded-lg border-secondary py-[18px] text-sm ring-0 focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50 data-[placeholder]:text-grey [&>span]:line-clamp-1 px-2 h-[42px] bg-transparent border-0 gap-1 transition-all">
            <span>{currentLocale.toUpperCase()}</span>
            <ChevronDownIcon />
          </button>
        </div>
      )}
      className="p-1 rounded-lg"
    >
      <Dropdown.Item className="py-1 px-1" onClick={() => handleChange("en")}>
        EN
      </Dropdown.Item>      
      <Dropdown.Item className="py-1 px-1" onClick={() => handleChange("fr")}>
        FR
      </Dropdown.Item>
    </Dropdown>
  );
}
