"use client";

import Image from "next/image";
import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const Section4 = () => {
  const { t } = useTranslation();

  const imageData = [
    { src: "/images/allianz_grey.svg", alt: "Allianz" },
    { src: "/images/baloise_grey.svg", alt: "Baloise" },
    { src: "/images/vaudoise_grey.svg", alt: "Vaudoise" },
    { src: "/images/zurich_grey.svg", alt: "Zurich" },
    { src: "/images/helvetia_grey.svg", alt: "Helvetia" },
  ];

  return (
    <div className="my-[60px] flex flex-col items-center justify-center md:my-[80px]">
      <div>
        <h2 className="mb-1 text-center text-3xl font-bold md:text-[34px]">
          {t("Home.Section4.key1")}
        </h2>
        <p className="text-center text-base font-medium leading-normal text-grey md:text-lg">
          {t("Home.Section4.key2")}
        </p>
      </div>
      <div className="max-w-screen-lg w-full">
        <div className="swiper-container py-[40px]">
          <Swiper
            spaceBetween={10} // Space between slides
            slidesPerView="auto" // Makes the number of visible slides automatic
            loop={true} // Infinite loop
            modules={[Autoplay]} // Import the Autoplay module
            autoplay={{
              delay: 1000, // Auto-slide interval in ms
              disableOnInteraction: true, // Allow autoplay even after user interaction
            }}
            speed={600} // Slide transition speed
            breakpoints={{
              // Custom breakpoints for different screen sizes
              320: {
                slidesPerView: 3, // On small screens, show 3 images at a time
              },
              768: {
                slidesPerView: 5, // On medium screens, show 5 images
              },
              1024: {
                slidesPerView: 4, // On larger screens, show 6 images
              },
            }}
            className="flex justify-center" // Center the swiper
          >
            {/* Map through imageData to create slides */}
            {imageData.map((image, index) => (
              <SwiperSlide key={index}>
                <Image
                  alt={image.alt}
                  loading="lazy"
                  width="162"
                  height="42"
                  className="h-[36px] w-[140px] flex-shrink-0 opacity-50 md:h-[42px] md:w-[162px]"
                  src={image.src}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Section4;
